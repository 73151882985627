<template>
    <div>
        <loading-modal :isShow="isLoadingQuiz"/>

        <div v-if="!isLoadingQuiz" class="md:-mt-3">

            <div class="header w-full h-80 bg-blue-500 text-center pt-6 px-4 rounded-b-3xl">

                <div class="mb-4">
                    <div class="bg-white p-1 rounded grid grid-cols-2 shadow md:w-2/4 w-3/4 mx-auto">
                        <router-link :to="{
                                name : 'QuizResultDetail',
                                params : {
                                    'singkatan_kategori': $route.params.singkatan_kategori,
                                    'id_paket_kuis_skor': paket_kuis_skor.id_paket_kuis_skor
                                }
                        }" class="bg-blue-500 rounded text-center text-white p-2 text-sm">
                            Nilai
                        </router-link>
                        <router-link :to="{
                            name : 'QuizReviewDetail',
                            params : {
                                'singkatan_kategori': $route.params.singkatan_kategori,
                                'id_kuis'           : paket_kuis_skor.id_kuis
                            }
                        }" class="bg-white rounded text-center text-gray-800 p-2 text-sm">
                            Pembahasan
                        </router-link>
                    </div>
                </div>

                <div class="flex items-center mb-2">
                    <div class="flex-none flex-grow">
                        <img src="@/assets/images/trophy.svg" style="height: 100px;" class="mb-2 animate__animated animate__tada "/>
                    </div>
                </div>
                <div class="flex items-center">
                    <div class="flex-wrap flex-grow">
                        <h2 class="text-left text-xl font-semibold text-white truncate font-custom-1" v-if="kategori != null">{{ kategori.judul }}</h2>
                        <h2 class="text-left text-3xl font-semibold text-white truncate font-custom-1" v-if="paket_kuis_skor != null">{{ paket_kuis_skor.kuis.judul_kuis }}</h2>
                    </div>

                </div>
            </div>


            <div class="hero -mt-12 px-4">
                <div class="relative mb-4 bg-white rounded-3xl shadow-md">
                    <div class="px-3 h-52 py-3">
                        <div class="text-center text-lg">
                            Standar Kompetensi Kelulusan
                        </div>
                        <div class="flex justify-center">
                            <div class="w-40 p-6 text-center" :key="index" v-for="(data, index) in kategori.sub_kategori">
                                <div class="rounded-full bg-green-500 ring-green-100 w-16 h-16 ring-4 mx-auto mb-3 font-bold text-xl text-white items-center flex align-middle">
                                    <div class="mx-auto">{{ Number(data.passing_grade) }}</div>
                                </div>
                                <h2 class="text-gray-800 font-semibold">{{ data.singkatan }}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="px-4" v-if="paket_kuis_skor != null">
                <div class="mb-2 text-lg text-center">
                    Nilai Detail
                </div>
                <div class="flex justify-center">
                    <div :class="Number(data.nilai) >= Number(data.passing_grade) ? 'border-green-500' : 'border-red-500' " class="mx-2 w-40 p-4 text-center rounded-lg  border-2" :key="index" v-for="(data, index) in nilai_detail">
                        <h2 class="font-bold text-2xl">{{ Number(data.nilai) }}</h2>
                        <h2 class="text-gray-800 font-semibold">{{ data.singkatan }}</h2>
                    </div>
                </div>
            </div>


            <div class="px-4 mt-6" v-if="paket_kuis_skor != null">
                <div class="mb-2 text-lg text-center">
                    Nilai Akhir
                </div>
                <div  class="w-60 p-4 text-center rounded-lg ring-4 mx-auto" :class="paket_kuis_skor.status_lulus == 1 ? 'ring-green-100 bg-green-600' : 'ring-red-100 bg-red-600' ">
                    <h2 class="text-white font-bold text-5xl">{{ Number(paket_kuis_skor.nilai_akhir) }}</h2>
                    <h2 class="text-white font-semibold">{{ paket_kuis_skor.status_lulus == 1 ? 'Anda dinyatakan lulus' : 'Anda tidak lulus' }}</h2>
                </div>
            </div>

            <div class="px-4 mt-4">
                <div id="chart" >
                    <vue-apex-charts
                        type="line" height="350" :options="chartOptions" :series="series"></vue-apex-charts>
                </div>
            </div>

            <div class="h-32"/>

            <div class="fixed right-0 left-0 md:bottom-0 bottom-12 shadow border border-gray-100 border-b-0 border-l-0 border-r-0" v-if="user != null">
                <router-link 
                    :to="{name: 'PeringkatDetail', params : {'singkatan_kategori' : kategori.singkatan.toLowerCase(), 'id_kuis' : paket_kuis_skor.id_kuis}}">
                    <div class="max-w-4xl mx-auto">
                        <div class="px-3 py-3 flex items-center bg-white">
                            <div class="w-10 h-10 flex-none rounded-full bg-gray-100 text-gray-800 font-semibold text-center items-center flex mr-3">
                                <div class="mx-auto">{{ peringkat.peringkat }}</div>
                            </div>
                            <img :src="user.avatar" class="w-14 h-14 rounded-full mr-2 hidden md:block"/>
                            <div class="flex-wrap flex-grow">
                                <h3 class="font-semibold truncate">{{ user.nama_lengkap }}</h3>
                                <h3>Peringkat {{ peringkat.peringkat }} dari {{ peringkat.total }}</h3>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>

   </div>
</template>
<script>
    import LoadingModal from '../../components/LoadingModal.vue';
    import { errorHandler, showErrorNotif } from '../../helpers/Tools';
    import VueApexCharts from 'vue-apexcharts'

    export default {
        name: 'QuizResultDetail',
        components: { LoadingModal,
          VueApexCharts
        },
        data(){
            return {
                isLoading          : true,
                isLoadingQuiz      : true,
                isLoadingPembagasan: false,
                kategori           : null,
                page               : 1,
                isOpenModalSkor    : false,
                paket_kuis_skor    : null,
                pembahasan         : [],
                user               : null,
                peringkat          : {},
                nilai_detail       : [],

                series: [{
                    name: 'Nilai Akhir',
                    data: [30, 40, 45, 50, 49, 60, 70, 81]
                }],
                chartOptions: {
                    chart: {
                        height: 350,
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'], 
                            opacity: 0.5
                        },
                    },
                    xaxis: {
                        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
                    },
                    points: []
                },

            }
        },
        mounted() {
			this.getKategoriDetail();
		},
		methods : {
            async getKategoriDetail() {
                this.isLoading = true;
                try {
                    let res = await this.$store.dispatch("getKategoriDetail", this.$route.params.singkatan_kategori)
                    let result = res.data
                    this.isLoading = false;
                    if(result.status == 'success') {
                        this.kategori = result.data;
                        this.getKuisListResult();
                    } 
                } catch (error) {
                    this.isLoading = false;
                    errorHandler(this, error);
                }
            },

            /**
             * 
             */
            async getKuisListResult() {
                this.isLoadingQuiz = true;
                          var me = this

                try {
                    let res = await this.$store.dispatch("getHasilKuis", {
                        id_paket_kuis_skor : this.$route.params.id_paket_kuis_skor
                    })
                    let result = res.data
                    this.isLoadingQuiz = false;
                    if(result.status == 'success') {
                        this.paket_kuis_skor = result.data.paket_kuis_skor;
                        this.pembahasan      = result.data.pembahasan;
                        this.user            = result.data.user;
                        this.peringkat       = result.data.peringkat;
                        this.nilai_detail    = result.data.nilai_detail

                        let skor_list = result.data.skor_list;
                        let series_data = [];
                        let categories_data = [];
                        for(var i = 0; i < skor_list.length; i++) {
                            series_data[i] = Number(skor_list[i].nilai_akhir);
                            categories_data[i] = `Percobaan ${skor_list[i].akses_ke}`;
                        }

                        this.series = [{
                            name : "Nilai Akhir",
                            data: series_data.reverse()
                        }]
                        this.chartOptions.xaxis.categories = categories_data.reverse();
                        // this.chartOptions.points = [{
                        //     x: "Percobaan 16",
                        //     y: 2,
                        //     marker: {
                        //         size: 8,
                        //         fillColor: '#fff',
                        //         strokeColor: 'red',
                        //         radius: 2,
                        //         cssClass: 'apexcharts-custom-class'
                        //     },
                        //     label: {
                        //         borderColor: '#FF4560',
                        //         offsetY: 0,
                        //         style: {
                        //             color: '#fff',
                        //             background: '#FF4560',
                        //         },
                        //         text: 'Point Annotation',
                        //     }
                        // }];
                    } else {
                        showErrorNotif(this, result.message);
                    }
                } catch (error) {
                    this.isLoadingQuiz = false;
                    errorHandler(this, error);
                }
            },

        }
    }
</script>
